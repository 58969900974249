import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { firstValueFrom } from 'rxjs';
import { ModalConfirmComponent } from '../../components/modal/confirm/confirm.component';
import { AuthService } from '../api/common/auth.service';
import { ExternalDomainConfig, SessionApiService } from '../api/session-api.service';
import { NotificationService } from '../pages/notification.service';
import { SpinnerService } from '../pages/spinner.service';

@Injectable({
  providedIn: 'root',
})
export class GoogleService {
  private scriptPromise: Promise<gapi.auth2.GoogleAuthBase> | null = null;
  private clientId: string | null = null;
  private scopes: string[] | null = null;

  public constructor(
    private authService: AuthService,
    private sessionApiService: SessionApiService,
    private spinnerService: SpinnerService,
    private notificationService: NotificationService,
    private matDialog: MatDialog,
  ) {}

  public async logIn(config: ExternalDomainConfig, username: string): Promise<void> {
    if (!config.scopes) {
      throw new Error('Invalid scopes');
    }
    this.scopes = config.scopes.split(';').map(item => 'https://www.googleapis.com/auth/' + item);
    this.clientId = config.webapp;

    try {
      const auth2 = await this.init();
      try {
        if (!auth2.currentUser.get().isSignedIn()) {
          await auth2.signIn();
        }
      } catch (error: unknown) {
        console.error(error);
        if (GoogleService.isGoogleError(error)) {
          if (error.error.startsWith('popup_blocked')) {
            this.spinnerService.hide();
            const dialog = ModalConfirmComponent.open(this.matDialog, {
              title: 'bkwa.login.external.google.title',
              text: 'bkwa.login.external.google.popupBlocked',
              acceptButton: 'bkwa.common.retry',
            });
            const result = await firstValueFrom(dialog.afterClosed());
            if (result) {
              this.spinnerService.show();
              await auth2.signIn({
                login_hint: username,
              });
            } else {
              this.authService.logOut(true);
              return;
            }
          }
        }
      }

      const currentUser = auth2.currentUser.get();
      const idToken = currentUser.getAuthResponse().id_token;
      for (const scope of this.scopes) {
        if (!currentUser.hasGrantedScopes(scope)) {
          auth2.disconnect();
          throw new InvalidScopesError('Not all scopes granted: ' + scope);
        }
      }
      await this.sessionApiService.loginExternal(idToken, 'GOOGLE');
      this.authService.logInExternal('GOOGLE', config, username, idToken, new Date(currentUser.getAuthResponse().expires_at)); // TODO: comprobar "expires_at"
      this.spinnerService.hide();
    } catch (error: unknown) {
      if (GoogleService.isGoogleError(error) && error.error === 'idpiframe_initialization_failed') {
        this.spinnerService.hide();
        ModalConfirmComponent.open(this.matDialog, {
          title: 'bkwa.login.external.google.title',
          text: 'bkwa.login.external.google.thirdPartyCookies',
          acceptButton: 'bkwa.common.accept',
          cancelButton: null,
        });
        this.authService.logOut(false);
      } else {
        throw error;
      }
    }
  }

  private init(): Promise<gapi.auth2.GoogleAuthBase> {
    if (this.scriptPromise === null) {
      if (this.scopes === null) {
        throw new Error('Undefined scopes');
      }
      if (this.clientId === null) {
        throw new Error('Undefined client ID');
      }
      const { scopes, clientId } = this;
      this.scriptPromise = new Promise<gapi.auth2.GoogleAuthBase>((resolve, reject) => {
        const script = document.createElement('script');
        script.src = 'https://apis.google.com/js/api.js';
        script.onload = () => {
          gapi.load('auth2', () => {
            gapi.auth2
              .init({
                client_id: clientId,
                scope: scopes.join(' '),
                // ux_mode: 'redirect',
                // redirect_uri: window.location.origin,
              })
              .then(resolve, reject);
          });
        };
        document.head.appendChild(script);
      });
    }
    return this.scriptPromise;
  }

  public async logOut(): Promise<void> {
    const auth2 = await this.init();
    auth2.signOut();
  }

  private static isGoogleError(error: unknown): error is { error: string } {
    // eslint-disable-next-line no-extra-parens
    return error != null && typeof error === 'object' && typeof (error as { error: unknown }).error === 'string';
  }
}

export class InvalidScopesError extends Error {
  public constructor(message: string) {
    super(message);
  }
}
