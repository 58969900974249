import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Theme, ThemeInterface } from '../../model/theme';
import { ThemeService } from '../pages/theme.service';
import { ApiPathBuilder, BaseApiService } from './common/base-api.service';

export type LoginType = 'NATIVE' | 'MICROSOFT' | 'GOOGLE';

@Injectable({
  providedIn: 'root',
})
export class SessionApiService extends BaseApiService {
  public constructor(http: HttpClient, private themeService: ThemeService) {
    super(http);
  }

  public checkDomain(email: string): Promise<DomainData> {
    return this._post<CheckDomainResponse>(ApiPathBuilder.v1('auth', 'domains'), { email }).then(response => {
      const theme = Theme.create(Theme.default);
      if (response.theme) {
        theme.populate(response.theme, true);
      }
      this.themeService.set(theme);
      return {
        authType: response.authType === 'OUTLOOK' ? 'MICROSOFT' : response.authType, // provisional
        config: response.config,
      };
    });
  }

  public loginNative(user: UserLogin): Promise<void> {
    return this._post<void>(ApiPathBuilder.v1('login'), user);
  }

  public loginExternal(token: string, type: Exclude<LoginType, 'NATIVE'>): Promise<void> {
    const headers = new HttpHeaders({
      'user-access-token': token,
    });
    const options: Record<Exclude<LoginType, 'NATIVE'>, string> = {
      MICROSOFT: 'outlook',
      GOOGLE: 'google',
    };
    return this._post<void>(ApiPathBuilder.v1('applications', options[type], 'validateuser'), null, headers);
  }

  public refresh(): Promise<void> {
    return this._get<void>(ApiPathBuilder.v1('login', 'refresh'));
  }

  public logout(): Promise<void> {
    return this._post<void>(ApiPathBuilder.v1('logout'), null).catch(error => {
      console.error('Logout error', error);
    });
  }
}

export class UserLogin {
  public username = '';
  public password = '';
}

export interface ExternalDomainConfig {
  clientId: string;
  name: string;
  scopes: string;
  defaultConfig: boolean;
  webapp: string;
}

export interface DomainData {
  authType: LoginType;
  config?: ExternalDomainConfig;
}

interface CheckDomainResponse {
  authType: Exclude<LoginType | 'OUTLOOK', 'MICROSOFT'>;
  theme?: ThemeInterface;
  config?: ExternalDomainConfig;
}
