import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { ModalConfirmComponent } from '../../../components/modal/confirm/confirm.component';
import { ReadonlyDate } from '../../../model/dates';
import { BaseFormPageDirective } from '../../../pages/base-form-page';
import { ExternalDomainConfig, LoginType, SessionApiService } from '../session-api.service';
import { BaseApiService } from './base-api.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private static readonly REDIRECT_AFTER_LOGIN_KEY = 'bkwa-redirect';
  private static readonly SESSION_KEY = 'bkwa-auth';
  private static readonly SESSION_TIMEOUT = (environment.sessionTimeout || 1) * 3600_000;

  private readonly observable: Subject<boolean>;
  private observableHasValue = false;
  private isLoggedIn = false;
  private loginInformation: LoginInformation;
  private scheduledLogoutTimeout: number | null = null;

  private externalConfig: ExternalDomainConfig | null = null;
  private externalToken: string | null = null;

  private expiration: ReadonlyDate | null = null;
  private expirationTimeout: number | null = null;

  public constructor(
    private router: Router,
    private cookieService: CookieService,
    sessionApi: SessionApiService,
    private matDialog: MatDialog,
  ) {
    this.loginInformation = LoginInformation.parse(this.cookieService.get(AuthService.SESSION_KEY)) ?? new LoginInformation(null, '');
    this.observable = new ReplaySubject<boolean>(1);

    // comprobar que la sesión no es antigua
    const lastLogin = window.localStorage.getItem(AuthService.SESSION_KEY);
    if (lastLogin) {
      const tsLastLogin = Number(lastLogin);
      if (isNaN(tsLastLogin) || Date.now() - tsLastLogin > AuthService.SESSION_TIMEOUT) {
        console.warn('Cerrando sesión por inactividad (sesión existente antigua)', lastLogin);
        this.loginInformation.loginType = null;
      }
    }

    if (!this.loginInformation.isLoggedIn()) {
      this.setIsLoggedIn(false);
    } else if (this.loginInformation.isNative()) {
      setImmediate(() => {
        sessionApi.refresh().then(
          () => {
            this.setIsLoggedIn(true);
          },
          error => {
            window.setTimeout(() => {
              if (!this.observableHasValue) {
                this.setIsLoggedIn(false); // el interceptor debería haber cerrado sesión en este punto
              }
            }, 1000);
            throw error;
          },
        );
      });
    }

    window.addEventListener('storage', event => {
      // mantener sincronizado inicio de sesión entre pestañas
      if (this.scheduledLogoutTimeout != null) {
        window.clearTimeout(this.scheduledLogoutTimeout);
        this.scheduledLogoutTimeout = null;
      }
      if (event.newValue === null) {
        if (this.isLoggedIn) {
          this.logOut(true);
        }
      } else {
        if (!this.isLoggedIn) {
          window.location.reload();
        }
      }
    });
  }

  public isLoggedInObservable(): Observable<boolean> {
    return this.observable;
  }

  public logIn(loginInformation: LoginInformation, expiration: ReadonlyDate | null = null, updateStatus = true): void {
    this.loginInformation = loginInformation;
    this.expiration = expiration;
    if (expiration != null) {
      if (this.getExternalTokenIfNeeded() != null) {
        // solo es necesario recargar si se envía el token al servidor
        let timeout = expiration.getTime() - Date.now();
        if (timeout < 15 * 60000) {
          console.warn('Expiration date from Microsoft is near', timeout);
          timeout = 15 * 60000;
        }
        this.expirationTimeout = window.setTimeout(() => {
          console.warn('Session has expired. Reloading…');
          BaseFormPageDirective.forceReload = true;
          window.location.reload();
        }, timeout);
      }
    }
    this.setLoginInformationCookie();
    if (updateStatus) {
      this.setIsLoggedIn(true);
    }
  }

  public logInExternal(
    loginType: Exclude<LoginType, 'NATIVE'>,
    config: ExternalDomainConfig,
    username: string,
    externalToken: string,
    expiration: ReadonlyDate | null,
  ): void {
    this.externalConfig = config;
    this.externalToken = externalToken;
    this.logIn(new LoginInformation(loginType, username), expiration);
  }

  public logOut(redirectToLogin: boolean): boolean {
    this.loginInformation.loginType = null;
    this.externalConfig = null;
    this.externalToken = null;

    this.expiration = null;
    if (this.expirationTimeout) {
      window.clearTimeout(this.expirationTimeout);
      this.expirationTimeout = null;
    }

    window.localStorage.removeItem(AuthService.SESSION_KEY);
    this.setLoginInformationCookie();

    BaseApiService.clear();
    this.setIsLoggedIn(false);
    if (redirectToLogin) {
      const previousRoute = this.router.url;
      this.matDialog.closeAll();
      this.router.navigate([ '/login' ]);
      return !previousRoute.startsWith('/login');
    } else {
      return true;
    }
  }

  private setIsLoggedIn(isLoggedIn: boolean): void {
    this.isLoggedIn = isLoggedIn;
    this.observable.next(isLoggedIn);
    this.observableHasValue = true;
    this.scheduleLogout();

    if (!environment.production && isLoggedIn) {
      this.clearSessionStorage(); // eliminar objetos en caché de login de microsoft
    }
  }

  private clearSessionStorage(): void {
    const url = window.sessionStorage.getItem(AuthService.REDIRECT_AFTER_LOGIN_KEY) || '';
    window.sessionStorage.clear();
    this.saveRedirectAfterLogin(url);
  }

  private setLoginInformationCookie(): void {
    this.cookieService.set(AuthService.SESSION_KEY, JSON.stringify(this.loginInformation), {
      expires: 10,
      secure: window.location.protocol === 'https:',
    });
  }

  public getLoginInformation(): LoginInformation {
    return this.loginInformation;
  }

  public getExternalToken(): string | null {
    return this.externalToken;
  }

  public getExternalTokenIfNeeded(): string | null {
    if (this.externalConfig && this.externalConfig.scopes !== '' && this.externalConfig.scopes !== 'user.read') {
      return this.externalToken;
    } else {
      return null;
    }
  }

  public scheduleLogout(): void {
    if (this.scheduledLogoutTimeout != null) {
      window.clearTimeout(this.scheduledLogoutTimeout);
      this.scheduledLogoutTimeout = null;
    }
    if (this.isLoggedIn) {
      window.localStorage.setItem(AuthService.SESSION_KEY, Date.now().toString());
      this.scheduledLogoutTimeout = window.setTimeout(() => {
        console.warn('Cerrando sesión por inactividad');
        this.logOut(true);

        ModalConfirmComponent.open(this.matDialog, {
          title: 'bkwa.login.sessionExpired.title',
          text: 'bkwa.login.sessionExpired.text',
          cancelButton: null,
        });
      }, AuthService.SESSION_TIMEOUT);
    }
  }

  public saveRedirectAfterLogin(url: string): void {
    if (url.startsWith('/')) {
      window.sessionStorage.setItem(AuthService.REDIRECT_AFTER_LOGIN_KEY, url);
    }
  }

  public getAndRemovePendingRedirect(): string | null {
    const url = window.sessionStorage.getItem(AuthService.REDIRECT_AFTER_LOGIN_KEY) || null;
    window.sessionStorage.removeItem(AuthService.REDIRECT_AFTER_LOGIN_KEY);
    return url;
  }
}

export class LoginInformation {
  public static parse(item: string | null): LoginInformation | null {
    if (item) {
      try {
        const parsed = JSON.parse(item);
        if (parsed && typeof parsed === 'object') {
          return new LoginInformation(parsed.loginType, parsed.username);
        }
      } catch (error: unknown) {
        console.error(error);
      }
    }
    return null;
  }

  public constructor(
    public loginType: LoginType | null,
    public readonly username: string,
  ) {}

  public isLoggedIn(): boolean {
    return this.loginType != null;
  }

  public isNative(): boolean {
    return this.loginType === 'NATIVE';
  }

  public isExternal(): boolean {
    return this.isLoggedIn() && !this.isNative();
  }
}
